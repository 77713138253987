<!--
.. Added by Kishore Jalleda
.. full list of modifications at https://github.com/unstructai
.. copyright: (c) 2023 Kishore Jalleda
.. author:: Kishore Jalleda <kjalleda@gmail.com>
-->
<template>
  <v-container>
    <v-row no-gutter align="center" style="margin-bottom: 20px;">
      <v-chip color="teal" text-color="white">
        <v-icon left>mdi-book-open-outline</v-icon>
        After-Activity Review Fields
      </v-chip>
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn icon color="info" v-bind="attrs" v-on="on" @click="addPostmortemField()">
            <v-icon large class="ml-2">mdi-playlist-plus</v-icon>
          </v-btn>
        </template>
        <span>These fields ensure that the draft of the After-Activity Review report has all the necessary information to be compiled effectively by AI.</span>
      </v-tooltip>
    </v-row>

    <draggable v-model="postmortemFields" @end="onDragEnd">
      <v-row v-for="(field, idx) in postmortemFields" :key="`postmortem-${idx}`" align="center" dense>

        <!-- Drag Handle with Tooltip -->
        <v-col cols="1">
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn icon v-on="on">
                <v-icon>mdi-drag-vertical</v-icon>
              </v-btn>
            </template>
            <span>Drag to reorder</span>
          </v-tooltip>
        </v-col>

        <!-- After-Activity Review Field Name -->
        <v-col cols="12" sm="5">
          <v-text-field
            label="After-Activity Review Field Name"
            :value="field.name"
            @input="updatePostmortemFieldName(idx, $event)"
          />
        </v-col>

        <!-- After-Activity Review Field Value -->
        <v-col cols="12" sm="5">
          <v-text-field
            label="After-Activity Review Field Value"
            :value="field.value"
            @input="updatePostmortemFieldValue(idx, $event)"
          />
        </v-col>

        <!-- Remove Button -->
        <v-col cols="1">
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn small icon @click="removePostmortemField(idx)" v-on="on">
                <v-icon>remove</v-icon>
              </v-btn>
            </template>
            <span>Remove After-Activity Review Field</span>
          </v-tooltip>
        </v-col>
      </v-row>
    </draggable>
  </v-container>
</template>


<script>
import { cloneDeep } from "lodash";
import draggable from "vuedraggable";

export default {
  name: "PostmortemCustomFields",

  components: {
    draggable,
  },

  props: {
    value: {
      type: Array,
      default: () => [],
    },
  },

  data() {
    return {
      postmortemFields: [],
    };
  },

  watch: {
    value: {
      immediate: true,
      handler(newVal) {
        this.postmortemFields = cloneDeep(newVal);
      },
    },
  },

  methods: {
    addPostmortemField() {
      if (!this.postmortemFields) {
        this.postmortemFields = []
      }
      this.postmortemFields.push({ name: null, value: null });
      this.$emit("input", this.postmortemFields);
    },

    removePostmortemField(idx) {
      this.postmortemFields.splice(idx, 1);
      this.$emit("input", this.postmortemFields);
    },

    updatePostmortemFieldName(idx, event) {
      this.postmortemFields[idx].name = event;
      this.$emit("input", this.postmortemFields);
    },

    updatePostmortemFieldValue(idx, event) {
      this.postmortemFields[idx].value = event;
      this.$emit("input", this.postmortemFields);
    },
    onDragEnd(event) {
      this.postmortemFields.forEach((item, index) => {
        item.order = index + 1;
      });
      this.$emit("input", this.postmortemFields);
    },
  },
};
</script>

<!--
.. Modified by Kishore Jalleda
.. full list of modifications at https://github.com/unstructai
.. copyright: (c) 2023 Kishore Jalleda
.. author:: Kishore Jalleda <kjalleda@gmail.com>
-->
<template>
  <v-container fluid>
    <new-edit-sheet />
    <v-row align="center" justify="space-between">
      <v-col cols="8">
        <settings-breadcrumbs v-model="project" />
      </v-col>
      <v-col class="text-right">
        <v-btn color="info" class="mr-3 mb-4" @click="createEditShow()">
          <v-icon class="mr-1" left>mdi-plus</v-icon>
          New IAO Type
        </v-btn>
      </v-col>
    </v-row>
    <v-row no-gutters>
      <v-col>
        <v-alert outlined dismissible icon="mdi-power-socket-uk" prominent type="info">
          <div>
            <h3>
              IAO Types Configuration
            </h3>
            <p>BAO types play a crucial role in categorizing business activities, allowing for tailored configurations that align with the specific needs of each IAO type within UnStruct.</p>
            <ul>
              <li><strong>Custom Configuration:</strong> Each incident type can be individually configured, offering flexibility in how IAO are managed and lead to desired outcomes</li>
              <li><strong>Visibility Settings:</strong> Control who can see IAOs of a particular type, ensuring sensitive information is accessible only to authorized individuals.</li>
              <li><strong>Default Settings:</strong> Set default values for IAO fields to streamline the creation process and maintain consistency across IAOs.</li>
              <li><strong>Custom Fields:</strong> Define custom fields for IAOs and After-Activity Reviews (AARs) to capture the unique data points relevant to each type.</li>
            </ul>
          </div>
        </v-alert>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" sm="6" md="4" v-for="item in items" :key="item.id">
        <v-card outlined class="ma-3 elevation-2">
          <v-card-title>
            <v-icon large left>mdi-power-socket-uk</v-icon>
            {{ item.name }}
            <v-spacer></v-spacer>
            <v-chip v-if="item.default" dark color="success" class="ma-2">
              <v-icon left>mdi-star</v-icon>
              Default
            </v-chip>
            <v-chip v-if="item.enabled" dark color="blue" class="ma-2">
                <v-icon left>mdi-lightbulb-on-outline</v-icon>
              Enabled
            </v-chip>
            <v-chip v-if="!item.enabled" class="ma-2">
              Disabled
            </v-chip>
            <v-chip v-if="item.visibility === 'Restricted'" color="warning" dark class="ma-2">
              <v-icon left>mdi-lock</v-icon>
              Private
            </v-chip>
            <v-chip v-if="item.visibility === 'Open'" color="success" dark class="ma-2">
              <v-icon left>mdi-eye-check</v-icon>
              Public
            </v-chip>
          </v-card-title>
          <v-card-subtitle class="py-4">
            <div class="chip-container">
              <div v-if="item.custom_fields.length > 0" icon class="ma-2">
                <v-icon color="primary" left>mdi-form-select</v-icon>
                Custom Fields
              </div>
              <div v-if="item.postmortem_custom_fields.length > 0" icon class="ma-2">
                <v-icon color="blue-grey" left>mdi-file-document-edit-outline</v-icon>
                After-Activity Review (AAR) Custom Fields
              </div>
              <div v-if="item.repo_urls && item.repo_urls.length > 0" icon class="ma-2">
                <v-icon color="brown" left>mdi-source-branch</v-icon>
                Repository URLs
              </div>
              <div v-if="item.custom_checklist.length > 0" icon class="ma-2">
                <v-icon color="teal" left>mdi-format-list-checks</v-icon>
                Custom Checklist
              </div>
              <div v-if="item.plugin_metadata.length > 0" icon class="ma-2">
                <v-icon color="indigo" left>mdi-puzzle-outline</v-icon>
                Plugin Metadata
              </div>
              <div v-if="item.storycurve_logging_enabled" icon class="ma-2">
                <v-icon color="cyan" left>mdi-strategy</v-icon>
                Storycurve Logging Enabled
              </div>
              <div v-if="item.incident_template_document" icon class="ma-2">
                <v-icon color="purple" left>mdi-file-document-outline</v-icon>
                IAO Template Document
              </div>
            </div>
          </v-card-subtitle>
          <v-card-text>
            <v-icon left>mdi-axis-arrow-info</v-icon>
            {{ item.description }}
          </v-card-text>
          <v-card-actions class="justify-end">
            <v-btn color="primary" icon @click="createEditShow(item)">
              <v-icon left>mdi-dots-vertical</v-icon>
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapFields } from "vuex-map-fields"
import { mapActions } from "vuex"

import NewEditSheet from "@/incident/type/NewEditSheet.vue"
import SettingsBreadcrumbs from "@/components/SettingsBreadcrumbs.vue"

export default {
  name: "IncidentTypeTable",

  components: {
    NewEditSheet,
    SettingsBreadcrumbs,
  },

  computed: {
    ...mapFields("incident_type", [
      "table.options.q",
      "table.options.page",
      "table.options.itemsPerPage",
      "table.options.sortBy",
      "table.options.descending",
      "table.options.filters.project",
      "table.loading",
      "table.rows.items",
      "table.rows.total",
    ]),
    ...mapFields("route", ["query"]),
  },

  created() {
    this.project = [{ name: this.query.project }]

    this.getAll()

    this.$watch(
      (vm) => [vm.page],
      () => {
        this.getAll()
      }
    )

    this.$watch(
      (vm) => [vm.q, vm.itemsPerPage, vm.sortBy, vm.descending, vm.project],
      () => {
        this.page = 1
        this.$router.push({ query: { project: this.project[0].name } })
        this.getAll()
      }
    )
  },

  methods: {
    ...mapActions("incident_type", ["getAll", "createEditShow", "removeShow"]),
  },
}
</script>

<style scoped>
.chip-container {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}
</style>

<template>
  <v-container>
    <v-row no-gutter align="center" style="margin-bottom: 20px;">
      <v-chip color="teal" text-color="white">
        <v-icon left>mdi-source-branch</v-icon>
        Repository URLs
      </v-chip>
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn icon color="info" v-bind="attrs" v-on="on" @click="addRepoUrl" class="ml-2">
            <v-icon large>mdi-playlist-plus</v-icon>
          </v-btn>
        </template>
        <span>Add Repository URLs so UnStruct can access your repositories, pull the latest commits, summarize them, and present all new IAO participants with deep insights.</span>
      </v-tooltip>
    </v-row>

    <!-- Section for adding/removing repository URLs -->
    <v-row v-for="(url, index) in repoUrls" :key="index" align="center" dense>
      <v-col cols="11">
        <v-text-field
          label="Repository URL"
          v-model="repoUrls[index]"
          dense
          outlined
          @input="emitInput"
        ></v-text-field>
      </v-col>
      <v-col cols="1">
        <v-icon @click="removeRepoUrl(index)">mdi-close</v-icon>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { cloneDeep } from 'lodash';

export default {
  name: 'RepositoryUrlManager',
  props: {
    value: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      repoUrls: [],
    };
  },
  watch: {
    value: {
      immediate: true,
      handler(newVal) {
        this.repoUrls = cloneDeep(newVal || []);
      },
    },
  },
  methods: {
    addRepoUrl() {
      this.repoUrls.push('');
    },

    removeRepoUrl(idx) {
      this.repoUrls.splice(idx, 1);
      this.emitInput();
    },

    emitInput() {
      this.$emit('input', this.repoUrls);
    },
  },
};
</script>

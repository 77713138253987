<!--
.. Mofified by Kishore Jalleda
.. full list of modifications at https://github.com/unstructai
.. copyright: (c) 2023 Kishore Jalleda
.. author:: Kishore Jalleda <kjalleda@gmail.com>
-->
<template>
  <ValidationObserver v-slot="{ invalid, validated }">
    <v-navigation-drawer v-model="showCreateEdit" app clipped right width="900">
      <template v-slot:prepend>
        <v-list-item two-line>
          <v-list-item-content>
            <v-list-item-title v-if="id" class="title"> Edit </v-list-item-title>
            <v-list-item-title v-else class="title"> New </v-list-item-title>
            <v-list-item-subtitle>BAO Type</v-list-item-subtitle>
          </v-list-item-content>
          <v-btn icon color="info" :loading="loading" :disabled="invalid || !validated" @click="save()">
            <v-icon>save</v-icon>
          </v-btn>
          <v-btn icon color="secondary" @click="closeCreateEdit()">
            <v-icon>close</v-icon>
          </v-btn>
        </v-list-item>
      </template>
      <v-card flat>
        <v-card-text>
          <v-container grid-list-md>
            <v-layout wrap>
              <v-flex xs12>
                <span class="subtitle-2">Details</span>
              </v-flex>
              <v-flex xs12>
                <ValidationProvider name="Name" rules="required" immediate>
                  <v-text-field v-model="name" slot-scope="{ errors, valid }" :error-messages="errors" :success="valid"
                    label="Name" hint="A name for your IAO type." clearable required />
                </ValidationProvider>
              </v-flex>
              <v-flex xs12>
                <ValidationProvider name="Description" rules="required" immediate>
                  <v-textarea v-model="description" slot-scope="{ errors, valid }" label="Description"
                    :error-messages="errors" :success="valid" hint="A description for your IAO type." clearable
                    required />
                </ValidationProvider>
              </v-flex>
              <v-flex xs12>
                <v-select v-model="visibility" label="Visibility" :items="visibilities"
                  hint="A visibility for your IAO type" clearable />
              </v-flex>
              <v-flex xs12>
                <incident-custom-fields v-model="custom_fields" :project="project" />
              </v-flex>
              <v-flex xs12>
                <repo-url-manager v-model="repo_urls" :project="project"/>
              </v-flex>
              <v-flex xs12>
                <incident-custom-checklist v-model="custom_checklist" :project="project" />
              </v-flex>
              <v-flex xs12>
                <postmortem-custom-fields v-model="postmortem_custom_fields" :project="project" />
              </v-flex>
              <v-flex xs12>
                <plugin-metadata-input v-model="plugin_metadata" :project="project" />
              </v-flex>
              <v-flex xs12>
                <ValidationObserver disabled>
                  <template-select :project="project" label="BAO Template" v-model="incident_template_document"
                    resource-type="dispatch-incident-document-template" />
                </ValidationObserver>
              </v-flex>
              <v-flex xs12>
                <ValidationObserver disabled>
                  <template-select :project="project" label="Executive Template" v-model="executive_template_document"
                    resource-type="dispatch-executive-report-document-template" />
                </ValidationObserver>
              </v-flex>
              <v-flex xs12>
                <ValidationObserver disabled>
                  <template-select :project="project" label="Review (AAR) Template" v-model="review_template_document"
                    resource-type="dispatch-incident-review-document-template" />
                </ValidationObserver>
              </v-flex>
              <v-flex xs12>
                <ValidationObserver disabled>
                  <template-select :project="project" label="Tracking Template" v-model="tracking_template_document"
                    resource-type="dispatch-incident-tracking-template" />
                </ValidationObserver>
              </v-flex>
              <v-flex xs12>
                <v-checkbox v-model="storycurve_logging_enabled" label="Storycurve Logging Enabled"
                  hint="Determines whether storycurve logging is enabled for this IAO type." />
              </v-flex>
              <v-flex xs 12>
                <v-checkbox v-model="exclude_from_metrics" label="Exclude From Metrics"
                  hint="Check if this IAO type should be excluded from all metrics." />
              </v-flex>
              <v-flex xs12>
                <v-checkbox v-model="default_incident_type" label="Default IAO Type"
                  hint="Check this if this IAO type should be the default." />
              </v-flex>
              <v-flex xs12>
                <v-checkbox v-model="enabled" label="Enabled"
                  hint="Determines whether this IAO type is availible for new activities" />
              </v-flex>
            </v-layout>
          </v-container>
        </v-card-text>
      </v-card>
    </v-navigation-drawer>
  </ValidationObserver>
</template>

<script>
import { ValidationObserver, ValidationProvider, extend } from "vee-validate"
import { mapActions } from "vuex"
import { mapFields } from "vuex-map-fields"
import { required } from "vee-validate/dist/rules"

import PluginMetadataInput from "@/plugin/PluginMetadataInput.vue"
import TemplateSelect from "@/document/template/TemplateSelect.vue"
import IncidentCustomFields from "@/incident/type/IncidentCustomFields.vue"
import PostmortemCustomFields from "@/incident/type/PostmortemCustomFields.vue"
import IncidentCustomChecklist from "@/incident/type/IncidentCustomChecklist.vue"
import RepoUrlManager from "@/incident/type/RepoUrlManager.vue"

extend("required", {
  ...required,
  message: "This field is required",
})

export default {
  name: "IncidentTypeNewEditSheet",

  components: {
    PluginMetadataInput,
    TemplateSelect,
    ValidationObserver,
    ValidationProvider,
    IncidentCustomFields,
    PostmortemCustomFields,
    IncidentCustomChecklist,
    RepoUrlManager,
  },

  data() {
    return {
      visibilities: ["Open", "Restricted"],
    }
  },

  computed: {
    ...mapFields("incident_type", [
      "dialogs.showCreateEdit",
      "selected.commander_service",
      "selected.liaison_service",
      "selected.description",
      "selected.id",
      "selected.project",
      "selected.loading",
      "selected.name",
      "selected.slug",
      "selected.incident_template_document",
      "selected.tracking_template_document",
      "selected.review_template_document",
      "selected.executive_template_document",
      "selected.plugin_metadata",
      "selected.custom_fields",
      "selected.repo_urls",
      "selected.postmortem_custom_fields",
      "selected.custom_checklist",
      "selected.visibility",
      "selected.enabled",
      "selected.exclude_from_metrics",
      "selected.storycurve_logging_enabled",
      "selected.default",
    ]),
    ...mapFields("incident_type", {
      default_incident_type: "selected.default",
    }),
    ...mapFields("route", ["query"]),
  },

  methods: {
    ...mapActions("incident_type", ["save", "closeCreateEdit"]),
  },
  created() {
    if (this.query.project) {
      this.project = { name: this.query.project }
    }
  },
}
</script>

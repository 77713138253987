<!--
.. Added by Kishore Jalleda
.. full list of modifications at https://github.com/unstructai
.. copyright: (c) 2023 Kishore Jalleda
.. author:: Kishore Jalleda <kjalleda@gmail.com>
-->
<template>
  <v-container>
    <v-row no-gutter align="center" style="margin-bottom: 20px;">
      <v-chip color="teal" text-color="white">
        <v-icon left>mdi-playlist-check</v-icon>
        IAO Custom Checklist
      </v-chip>
      <v-tooltip bottom class="ml-2">
        <template v-slot:activator="{ on, attrs }">
          <v-btn icon large color="primary" v-bind="attrs" v-on="on" @click="addItem()">
            <v-icon large>mdi-playlist-plus</v-icon>
          </v-btn>
        </template>
        <span>Custom Checklists help you define a list of items that need to be checked off during an incident. You can also define SLAs for each item and choose to remind the commander if the SLA is missed.</span>
      </v-tooltip>
    </v-row>

    <draggable v-model="checklistItems" @end="onDragEnd">
      <v-row v-for="(item, idx) in checklistItems" :key="item.id" align="center" dense>
        <v-col cols="12" sm="1">
          <!-- Drag Handle with Tooltip for visual cues. KJ.-->
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn icon v-on="on">
                <v-icon>mdi-drag-vertical</v-icon>
              </v-btn>
            </template>
            <span>Drag to reorder</span>
          </v-tooltip>
        </v-col>
        <v-col cols="12" sm="7">
          <v-text-field
            label="Item"
            :value="item.name"
            @input="updateItemName(idx, $event)"
          />
        </v-col>
        <v-col cols="12" sm="4">
          <v-text-field
            label="SLA (in minutes)"
            :value="item.sla"
            @change="updateItemSLA(idx, $event)"
          />
        </v-col>
        <v-col cols="12" sm="3">
          <v-checkbox
            label="Remind Commander on SLA Miss"
            :input-value="item.remind_commander_on_sla_miss === 'True' ? true : false"
            @change="updateItemRemindCommander(idx, $event)"
          />
        </v-col>
        <v-col cols="12" sm="1">
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn small icon @click="removeItem(idx)" v-on="on">
                <v-icon>remove</v-icon>
              </v-btn>
            </template>
            <span>Remove Item</span>
          </v-tooltip>
        </v-col>
      </v-row>
    </draggable>
  </v-container>
</template>



<script>
import { cloneDeep } from "lodash";
import draggable from "vuedraggable";

export default {
  name: "IncidentCustomChecklist",

  components: {
    draggable,
  },

  props: {
    value: {
      type: Array,
      default: () => [],
    },
  },

  data() {
    return {
      checklistItems: [],
    };
  },

  watch: {
    value: {
      immediate: true,
      handler(newVal) {
        this.checklistItems = cloneDeep(newVal);
      },
    },
  },

  methods: {
    addItem() {
      if (!this.checklistItems) {
      this.checklistItems = []
      }
      this.checklistItems.push({ name: '', sla: '', remind_commander_on_sla_miss: false});
      this.$emit("input", this.checklistItems);
    },
    removeItem(idx) {
      this.checklistItems.splice(idx, 1);
      this.$emit("input", this.checklistItems);
    },
    updateItemSLA(idx, event) {
      let sla = parseInt(event, 10);
      if (!isNaN(sla) && sla > 0) {
          this.checklistItems[idx].sla = sla;
      } else {
          // reset the value
          this.checklistItems[idx].sla = '';
      }
      this.$emit("input", this.checklistItems);
    },
    updateItemRemindCommander(idx, event) {
      this.checklistItems[idx].remind_commander_on_sla_miss = event;
      this.$emit("input", this.checklistItems);
    },
    updateItemName(idx, event) {
      this.checklistItems[idx].name = event
      this.$emit("input", this.checklistItems)
    },
    updateItemValue(idx, event) {
      this.checklistItems[idx].value = event;
      this.$emit("input", this.checklistItems);
    },
    onDragEnd(event) {
      this.checklistItems.forEach((item, index) => {
        item.order = index + 1;
      });
      this.$emit("input", this.checklistItems);
    },
  },
}
</script>
